import { MantineProvider } from '@mantine/core';
import { memo, useEffect, useMemo } from 'react';
import { useThemeStore } from '~/components/theme/useThemeStore';
import TitleSymbolQuote from '~/modules/investment-consultant/symbolList/body/SymbolQuoteTitle';
import { useSnapshot } from 'valtio';
import { store } from '../heineken_template/_private/store';
import IntradayTrendChart from '~/modules/trendChart';
import { GiRadarSweep } from 'react-icons/gi';
import { MdAddchart } from 'react-icons/md';
import { useBig3ConsecutiveResource } from '~/modules/screener/containers/useStockScreenerResource';
import { staticStore } from '../heineken_template/_private/staticStore';
import { jerry597_store } from './jerry597_store';
import { useFirebaseValue } from '~/hooks/useFirebaseValue';
import { AddSymbolButtonSmall } from '~/modules/WatchList/AddSymbolToWatchListButton';
import { fill_horizontal_all_center, fill_vertical_all_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import { SymbolModal } from './component/SymbolModal';
import useMedia from '~/hooks/useMedia';
import { SymbolQuoteWithKbar, SymbolQuoteWithKbarHeader, } from '../investment_demo/component/symbolQuote/SymbolQuoteWithKbar';
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2';
import { useStockWeightResource } from '~/modules/chips/useChipResource';
import styled from '@emotion/styled';
import { scrollbar2Css } from '~/css/scrollbarCss';
import { useSignalr2QuoteSort } from '~/modules/symbolPlatform/useSignalr2QuoteSort';
import { css } from '@emotion/react';
import { WatchListSymbolQuote } from './component/WatchListSymbolQuote';
const Jerry597SymbolQuote = memo(function Jerry597SymbolQuote(props) {
    const theme = useThemeStore(state => state.theme);
    const charting = useSnapshot(store).charting;
    const active = charting.symbol === props.symbol;
    const classes = {
        body: styled.div `
        width: 100%;
        height: 40px;
        border-radius: 2px;
        &.dark {
          background-color: #181818;
          border: 1px solid ${props_ => (props_.active ? '#ffffff' : 'transparent')};
          &:hover {
            border: 1px solid #aaaaaa;
          }
        }
        &.light {
          background-color: #fcfcfc;
          border: 1px solid ${props_ => (props_.active ? '#151515' : 'transparent')};
          &:hover {
            border: 1px solid #676767;
          }
        }
      `,
    };
    return (<classes.body active={active} className={theme} onClick={() => store.charting.changeSymbol(props.symbol)}>
        <SymbolQuoteWithKbar symbol={props.symbol}/>
      </classes.body>);
});
export const Jerry597_SidePane1 = memo(function Jerry597_SidePane1() {
    const theme = useThemeStore(state => state.theme);
    const charting = useSnapshot(store.charting);
    const state = useSnapshot(jerry597_store);
    const { isPc } = useMedia();
    const lastTradeDate = useSnapshot(staticStore).tradedDate.day0;
    /** 自選股 */
    const watchListGroupString = 'jerry597_stock_group_1';
    /** Firebase老師自行輸入精選股 */
    const long = useFirebaseValue('jerry597_signal', 'long');
    const longSymbolData = useBig3ConsecutiveResource({
        date: lastTradeDate.format('YYYY-MM-DD'),
        days: 3,
        action: 'buy',
        institution: 'foreigner',
        amount_gte: 100000000,
        volume_gte: 5000,
    });
    const shortSymbolData = useBig3ConsecutiveResource({
        date: lastTradeDate.format('YYYY-MM-DD'),
        days: 5,
        action: 'sell',
        institution: 'foreigner',
        amount_gte: 100000000,
        volume_gte: 5000,
    });
    const longSymbol = longSymbolData.data?.map(s => s.symbol)?.slice(0, 10) ?? [];
    const shortSymbol = shortSymbolData.data?.map(s => s.symbol)?.slice(0, 10) ?? [];
    useEffect(() => {
        signalrStore2.addQuote(longSymbol.concat(shortSymbol));
        return () => {
            signalrStore2.removeQuote(longSymbol.concat(shortSymbol));
        };
    }, [JSON.stringify(longSymbol.concat(shortSymbol))]);
    //
    const staticState = useSnapshot(staticStore);
    const twseWeight = useStockWeightResource('TWSE');
    const tw50Stock = staticState.symbol.tw50;
    const tw50Data = useMemo(() => {
        return twseWeight
            .filter(([k]) => tw50Stock?.indexOf(k) !== -1)
            .map(([k, v]) => ({ symbol: k, percentage: v.percentage }))
            .sort((a, b) => b.percentage - a.percentage);
    }, [tw50Stock, twseWeight]).slice(0, 20);
    const tw50Symbol = tw50Data.map(s => s.symbol) ?? [];
    useEffect(() => {
        signalrStore2.addQuote(tw50Symbol);
        return () => {
            signalrStore2.removeQuote(tw50Symbol);
        };
    }, [JSON.stringify(tw50Symbol)]);
    const aaa = useSignalr2QuoteSort(tw50Symbol) ?? [];
    return (<MantineProvider theme={{ colorScheme: theme }}>
      <classes.container className={theme}>
        <classes.intradayChart.body className={theme}>
          <classes.intradayChart.header className={theme}>
            <TitleSymbolQuote.Default symbol={charting.symbol}/>
          </classes.intradayChart.header>
          <IntradayTrendChart symbol={charting.symbol} ticksSize={12} ticksHeight={16} transparency={0.4} priceTicksSize={12} priceTicksMargin={-15}/>
        </classes.intradayChart.body>
        <classes.watchListAddButton.body>
          <div css={fill_vertical_all_center}>自選股狀態</div>
          <AddSymbolButtonSmall groupName={watchListGroupString} symbolNumber={charting.symbol} listSize={50}/>
        </classes.watchListAddButton.body>

        <classes.strtegy.container className={theme}>
          <classes.strtegy.header className={theme}>
            <MdAddchart size={'30px'} fill={'#ff5500'}/>
            功能選擇
          </classes.strtegy.header>
          <classes.strtegy.body>
            <classes.strtegy.button className={theme} active={state.pageMode === 'screener'} onClick={() => (jerry597_store.pageMode = 'screener')}>
              每日選股
            </classes.strtegy.button>
            <classes.strtegy.button className={theme} active={state.pageMode === 'pro'} onClick={() => (jerry597_store.pageMode = 'pro')}>
              權值股
            </classes.strtegy.button>
            <classes.strtegy.button className={theme} active={state.pageMode === 'watchlist'} onClick={() => (jerry597_store.pageMode = 'watchlist')}>
              自選股
            </classes.strtegy.button>
          </classes.strtegy.body>
        </classes.strtegy.container>

        {state.pageMode === 'screener' && (<classes.symbolList.body className={theme}>
            <classes.symbolList.title className={theme}>
              <GiRadarSweep size={'30px'} fill={'#ff5500'}/>
              金旺選股 &nbsp;
              {isPc && (<SymbolModal symbolLong={longSymbol} symbolShort={shortSymbol} symbolFeatured={long?.symbols ?? []}/>)}
            </classes.symbolList.title>
            <classes.symbolList.title className={theme}>
              <classes.symbolList.buttonLong className={theme} active={state.screener === 'long'} onClick={() => (jerry597_store.screener = 'long')}>
                金旺多方選股
              </classes.symbolList.buttonLong>
              <classes.symbolList.buttonShort className={theme} active={state.screener === 'short'} onClick={() => (jerry597_store.screener = 'short')}>
                金旺空方選股
              </classes.symbolList.buttonShort>
            </classes.symbolList.title>

            <classes.symbolList.list css={css `
                display: grid;
                grid-template-rows: 36px 1fr;
              `}>
              <SymbolQuoteWithKbarHeader />
              <div>
                {state.screener === 'long' &&
                longSymbol.map(s => {
                    return (<Jerry597SymbolQuote symbol={s} key={s}/>);
                })}
                {state.screener === 'short' &&
                shortSymbol.map(s => {
                    return (<Jerry597SymbolQuote symbol={s} key={s}/>);
                })}
              </div>
            </classes.symbolList.list>
          </classes.symbolList.body>)}
        {state.pageMode === 'pro' && (<classes.symbolList.body css={css `
              display: grid;
              grid-template-rows: 36px 1fr;
            `}>
            <SymbolQuoteWithKbarHeader />
            {aaa.map(s => {
                return (<Jerry597SymbolQuote symbol={s} key={s}/>);
            })}
          </classes.symbolList.body>)}
        {state.pageMode === 'watchlist' && (<classes.symbolList.body css={css `
              display: grid;
              grid-template-rows: 36px 1fr;
            `}>
            <SymbolQuoteWithKbarHeader />
            <WatchListSymbolQuote groupName={watchListGroupString}>
              {symbol => <Jerry597SymbolQuote symbol={symbol}/>}
            </WatchListSymbolQuote>
          </classes.symbolList.body>)}
      </classes.container>
    </MantineProvider>);
});
const classes = {
    container: styled.div `
    ${fill_vertical_cross_center};
    gap: 4px;
    padding: 4px;
    &.dark {
      background-color: #232323;
      color: #ffffff;
    }
    &.light {
      background-color: #eeeeee;
      color: #232222;
    }
  `,
    intradayChart: {
        header: styled.div `
      ${fill_horizontal_all_center};
      height: 32px;
      font-size: 20px;
      color: #ffffff;
      border-radius: 2px;
      gap: 8px;
      font-weight: 600;
      &.dark {
        background-color: #333333;
        color: #ffffff;
      }
      &.light {
        background-color: #dedede;
        color: #2a2a2a;
      }
    `,
        body: styled.div `
      ${fill_vertical_cross_center};
      height: 172px;
      border-radius: 4px;
      padding: 4px;
      &.dark {
        background-color: #343434;
      }
      &.light {
        background-color: #ececec;
      }
    `,
    },
    strtegy: {
        container: styled.div `
      ${fill_vertical_cross_center};
      height: 68px;
      border-radius: 4px;
      padding: 4px;
      gap: 2px;
      &.dark {
        background-color: #323232;
        color: #ffffff;
      }
      &.light {
        background-color: #ececec;
        color: #252525;
      }
    `,
        header: styled.div `
      ${fill_horizontal_all_center};
      height: 30px;
      font-weight: 600;
      gap: 4px;
    `,
        body: styled.div `
      ${fill_horizontal_all_center};
      gap: 4px;
      height: 30px;
    `,
        button: styled.div `
      ${fill_vertical_all_center}
      width: 100%;
      height: 28px;
      border-radius: 2px;
      font-size: 14px;
      cursor: pointer;
      transition: 0.5s;
      font-weight: 400;
      &:hover {
        border: 1px solid #fafafa;
        transition: 0.5s;
      }
      &.dark {
        background-color: ${props => (props.active === true ? '#bd3b1e' : '#252525')};
        color: ${props => (props.active === true ? '#fafafa' : '#aaaaaa')};
        border: 1px solid #878787;
        &:hover {
          border: 1px solid #ffffff;
          transition: 0.5s;
        }
      }
      &.light {
        background-color: ${props => (props.active === true ? '#bd3b1e' : '#cdcdcd')};
        color: ${props => (props.active === true ? '#fafafa' : '#676767')};
        border: 1px solid #aaaaaa;
        &:hover {
          border: 1px solid #252525;
          transition: 0.5s;
        }
      }
    `,
    },
    symbolList: {
        body: styled.div `
      ${scrollbar2Css};
      height: calc(100% - 284px);
      gap: 2px;
    `,
        list: styled.div `
      ${scrollbar2Css};
      height: calc(100% - 72px);
      gap: 2px;
      padding: 2px;
    `,
        title: styled.div `
      ${fill_horizontal_all_center};
      height: 32px;
      gap: 4px;
      border-radius: 4px;
      &.dark {
        background-color: #323232;
      }
      &.light {
        background-color: #dedede;
      }
    `,
        buttonLong: styled.div `
      ${fill_horizontal_all_center};
      height: 28px;
      border-radius: 4px;
      cursor: pointer;
      font-size: 14px;
      &.dark {
        background-color: ${props => (props.active ? '#ff0000' : '#660000')};
        color: ${props => (props.active ? '#ffffff' : '#aaaaaa')};
      }
      &.light {
        background-color: ${props => (props.active ? '#ff0000' : '#660000')};
        color: ${props => (props.active ? '#ffffff' : '#aaaaaa')};
      }
    `,
        buttonShort: styled.div `
      ${fill_horizontal_all_center};
      height: 28px;
      border-radius: 4px;
      cursor: pointer;
      font-size: 14px;
      &.dark {
        background-color: ${props => (props.active ? '#00aa00' : '#006600')};
        color: ${props => (props.active ? '#ffffff' : '#aaaaaa')};
      }
      &.light {
        background-color: ${props => (props.active ? '#00aa00' : '#005500')};
        color: ${props => (props.active ? '#ffffff' : '#aaaaaa')};
      }
    `,
    },
    watchListAddButton: {
        body: styled.div `
      display: grid;
      grid-template-columns: 40% 60%;
      font-size: 14px;
      height: 30px;
      width: 100%;
      &.dark {
        background-color: #424242;
        color: #ffffff;
      }
      &.light {
        background-color: #acacac;
        color: #262626;
      }
    `,
    },
};
