import styled from '@emotion/styled';
import { memo, useCallback, useEffect } from 'react';
import { fill_horizontal_all_center, fill_horizontal_cross_center, fill_vertical_all_center, fill_vertical_cross_center, jc, } from '~/modules/AppLayout/FlexGridCss';
import { scrollbar2Css } from '~/css/scrollbarCss';
import KbarSymbolQuote from '../quote/KbarSymbolQuote';
import { edwinGroupName } from '../WatchListGroupName';
import { Button, Chip, Select, Tooltip } from '@mantine/core';
import { FcCalendar } from 'react-icons/fc';
import { proxy, useSnapshot } from 'valtio';
import { staticStore } from '~/pages/heineken_template/_private/staticStore';
import { edwin_store } from '../../edwin_store';
import dayAPI from '~/utils/dayAPI';
import { css } from '@emotion/react';
import { useMount } from 'react-use';
import { useSortSignalrDataStore } from '~/modules/symbolQuote/simple/useSortStore';
import { useVirtualAccountOrderPre, useVirtualTransaction, } from '~/modules/virtualExchange/useVirtualExchangeResource';
import { SortArrow } from './SortArrow';
import { sortBy } from 'lodash';
import { useSymbolWatchList } from '~/modules/WatchList/useSymbolWatchList';
import { EmptyScreenerDataView } from '../EmptyScreenerDataView';
import { store } from '~/pages/heineken_template/_private/store';
import { edwin_strategiesGroup } from '../../edwin_initStrategies';
const rankColor = (day) => {
    if (day >= 4) {
        return '#ff2f28';
    }
    else if (day === 3) {
        return '#cb524c';
    }
    else if (day === 2) {
        return '#8b524c';
    }
    else
        return '#5b5f62';
};
export const screenerDataStore = proxy({
    sortKey: 'none',
    orderKey: 'desc',
});
const ScreenerTypeButton = memo(function ScreenerTypeButton(props) {
    return (<Button css={css `
        width: 100%;
        height: 28px;
        font-size: 13px;
        font-weight: 500;
        background-color: ${props.color};
        &:hover {
          background-color: ${props.color};
          border: 1px solid #acacac;
        }
      `} 
    //color={props.color}
    variant='filled' radius='md' onClick={() => {
            edwin_store.screenType = props.action;
            store.charting.setStrategy(props.strategies);
            store.charting.updateFromStrategyState();
        }}>
      {props.children}
    </Button>);
});
export const ScreenerContent = memo(function ScreenerContent(props) {
    const watchList = useSymbolWatchList(edwinGroupName);
    const value = props.quoteValue;
    const symbols = props.symbols;
    const tradedDate = useSnapshot(staticStore).tradedDate;
    //每5分拿一次最新交易日 //fix 檢查
    useEffect(() => {
        const intervalId = setInterval(() => {
            tradedDate;
        }, 5 * 1000 * 60);
        return () => clearInterval(intervalId);
    }, [tradedDate]);
    const day0 = tradedDate.day0;
    const intraday = tradedDate.intraday;
    const state = useSnapshot(edwin_store);
    const historyDate = tradedDate.days.map(s => s.format('YYYY-MM-DD'));
    //選股這頁 預設排序金額
    //首次載入把選股日、圖表顯示日設為最近一個交易日
    useMount(() => {
        useSortSignalrDataStore.sortKey = 'amount';
        edwin_store.screenerDate = dayAPI(day0).format('YYYY-MM-DD');
    });
    //在選擇 即時/歷史 給予對應的mantain使用的選股日期 ------------------------------------------------
    const screenerDateTimeData = useCallback(() => {
        if (state.screenerType === 'history') {
            return historyDate;
        }
        else {
            return [intraday.format('YYYY-MM-DD')];
        }
    }, [JSON.stringify(historyDate), state.screenerType, intraday]);
    const screenerDateData = screenerDateTimeData();
    //在選擇 即時/選股 給予對應的日期 ------------------------------------------------
    useEffect(() => {
        if (state.screenerType === 'realTime') {
            edwin_store.screenerDate = intraday.format('YYYY-MM-DD');
        }
        if (state.screenerType === 'history') {
            edwin_store.screenerDate = screenerDateData[0];
        }
    }, [screenerDateData, state.screenerType, intraday]);
    //上榜天數特別排序 ------------------------------------------------
    const screenerSortState = useSnapshot(screenerDataStore);
    const sortState = useSnapshot(useSortSignalrDataStore);
    //目前用戶選股日在可選範圍資料的第幾項
    const screenerDateIndex = screenerDateData.indexOf(state.screenerDate);
    //找出近期十天所有上榜的標的與紀錄上榜次數
    //假設最新11/05 用戶選11/01
    //endDatetime=11/01 beginDatetime在可選日的陣列中找到11/01 再往後找5天(如果你只想找近期5天的資料)
    const transition = useVirtualTransaction('edwin', {
        beginDatetime: historyDate[screenerDateIndex + 8],
        endDatetime: edwin_store.screenerDate,
    });
    const displayDate = historyDate[screenerDateIndex + 9];
    //check 如果最新交易日有資料 代表已經收盤了
    const checkPosition = useVirtualTransaction('edwin', {
        beginDatetime: intraday.format('YYYY-MM-DD'),
        endDatetime: intraday.format('YYYY-MM-DD'),
    });
    //找出歷史新倉進場的商品
    const transitionSymbol = transition
        ?.filter(s => s.bs === 'B' && s.positionType === 'O')
        .map(s => s.symbol) ?? [''];
    //紀錄上榜次數 返回商品、上榜次數
    const transactionData = transitionSymbol.reduce((acc, symbol) => {
        const existingItem = acc.find(item => item.symbol === symbol);
        if (existingItem) {
            existingItem.count += 1;
        }
        else {
            acc.push({ count: 1, symbol }); //transitionSymbol == 3 => count == 2
        }
        return acc;
    }, []);
    //如果昨天有出現訊號 需要用pr-eorder來做確認(盤中要) 因為transition慢一天
    const preOrderData = useVirtualAccountOrderPre('edwin', 'B', {
        dateTime: day0.format('YYYY-MM-DD'),
    })?.map(s => s.symbol) ?? [''];
    //將singlar與自製的transition天數合併
    const countScreenerDays = value.map(quote => {
        //一般歷史
        const matchingData = transactionData.find(item => item.symbol === quote.symbol);
        //前一天要進場的股票
        const matchingPreOrderData = preOrderData.find(item => item === quote.symbol);
        //盤中:今天、昨天、前天有進場
        if (matchingData && matchingPreOrderData && checkPosition?.length === 0) {
            return { singlar: quote, count: matchingData.count + 2 };
        }
        //盤中:今天、昨天有進場
        if (!matchingData && matchingPreOrderData && checkPosition?.length === 0) {
            return { singlar: quote, count: 2 };
        }
        //盤中:今天、前天有進場
        if (matchingData && !matchingPreOrderData && checkPosition?.length === 0) {
            return { singlar: quote, count: matchingData.count + 1 };
        }
        //盤後:
        if (matchingData) {
            return { singlar: quote, count: matchingData.count + 1 };
        }
        //盤中:
        return { singlar: quote, count: 1 };
    });
    const toggleTrustOrderKey = screenerSortState.orderKey === 'desc' ? 'asc' : 'desc';
    const handleTurstSelected = useCallback((sortKey) => {
        ;
        (screenerDataStore.sortKey = sortKey),
            (screenerDataStore.orderKey = toggleTrustOrderKey),
            (useSortSignalrDataStore.sortKey = 'none');
    }, [toggleTrustOrderKey]);
    useEffect(() => {
        if (sortState.sortKey !== 'none') {
            screenerDataStore.sortKey = 'none';
        }
    }, [sortState.sortKey]);
    const sortData = () => {
        //如果有用天數排行
        if (screenerSortState.sortKey === 'day' && screenerSortState.orderKey === 'asc') {
            return sortBy(countScreenerDays, datum => datum.count);
        }
        if (screenerSortState.sortKey === 'day' && screenerSortState.orderKey === 'desc') {
            return sortBy(countScreenerDays, datum => datum.count).reverse();
        }
        //如果沒有用天數排行
        return countScreenerDays;
    };
    //載入動畫 ------------------------------------------------
    const loadComplete = symbols?.length !== 0;
    return (<styleds.container>
      <styleds.selecter>
        <ScreenerTypeButton strategies={edwin_strategiesGroup.main[0]} color={state.screenType === 'strong' ? '#ce423a' : '#353a3f'} action={'strong'}>
          強勢
        </ScreenerTypeButton>
        <ScreenerTypeButton strategies={edwin_strategiesGroup.main[1]} color={state.screenType === 'weak' ? '#529c4f' : '#353a3f'} action={'weak'}>
          弱勢
        </ScreenerTypeButton>
      </styleds.selecter>
      <styleds.selecter>
        <Chip css={css `
            .mantine-hwb60 {
              width: 72px;
              height: 28px;
              background-color: #252931;
            }
          `} checked={state.screenerType === 'realTime'} onChange={() => (edwin_store.screenerType = 'realTime')} size={'xs'} radius='md' color='yellow'>
          即時
        </Chip>
        <Chip css={css `
            .mantine-hwb60 {
              width: 72px;
              height: 28px;
              background-color: #252931;
            }
          `} checked={state.screenerType === 'history'} onChange={() => (edwin_store.screenerType = 'history')} size={'xs'} radius='md' color='yellow'>
          歷史
        </Chip>

        <Select css={css `
            .mantine-58jpum {
              width: 160px;
              background-color: #292c33;
            }
          `} disabled={state.screenerType === 'realTime' ? true : false} icon={<FcCalendar size='24px'/>} defaultValue={screenerDateData[0]} value={state.screenerDate} data={screenerDateData.slice(0, 15)} onChange={(date_) => {
            edwin_store.screenerDate = date_;
        }}/>
      </styleds.selecter>
      <styleds.header>
        <styleds.headerItem>
          <styleds.listedContent selectd={screenerSortState.sortKey === 'day'} onClick={() => {
            handleTurstSelected('day');
        }}>
            <Tooltip label={'計算起始日：' + displayDate}>
              <span>累積上榜</span>
            </Tooltip>
            <SortArrow sortKey='day' sotre={screenerDataStore}/>
          </styleds.listedContent>
          <KbarSymbolQuote.header displayKbarSort={false}/>
        </styleds.headerItem>
      </styleds.header>
      <styleds.body>
        {loadComplete &&
            sortData()?.map((quote, index) => {
                const days = quote.count;
                return (<styleds.bodyItem key={index}>
                <styleds.listed css={css `
                    background-color: ${rankColor(days)};
                  `}>
                  {days <= 1 ? days : days}
                </styleds.listed>
                <KbarSymbolQuote.body symbolWatchList={watchList} quote={quote.singlar}/>
              </styleds.bodyItem>);
            })}
        {!loadComplete && <EmptyScreenerDataView />}
      </styleds.body>
    </styleds.container>);
});
const styleds = {
    container: styled.div `
    position: relative;
    ${fill_vertical_cross_center};
    gap: 4px;
  `,
    selecter: styled.div `
    ${fill_horizontal_cross_center};
    ${jc.spaceBetween};
    width: 100%;
    height: 36px;
    gap: 4px;
  `,
    header: styled.div `
    width: 100%;
    height: 36px;
    flex-shrink: 0;
  `,
    headerItem: styled.div `
    position: relative;
    ${fill_horizontal_all_center};
    display: grid;
    grid-template-columns: 13px 1fr;
    width: 100%;
    height: 36px;
    border-radius: 4px;
    background-color: #1b1e26;
  `,
    body: styled.div `
    position: relative;
    ${fill_vertical_cross_center};
    ${scrollbar2Css};
    height: calc(100% - 110px);
    gap: 2px;
  `,
    bodyItem: styled.div `
    ${fill_horizontal_all_center};
    display: grid;
    grid-template-columns: 14px 1fr;
    position: relative;
    width: 100%;
    height: 36px;
    border-radius: 4px;
    background-color: #151820;
  `,
    listed: styled.div `
    ${fill_vertical_all_center};
    border-radius: 4px 0 0 4px;
    font-size: 10px;
    font-weight: 500;
  `,
    listedContent: styled.div `
    ${fill_horizontal_all_center};
    cursor: pointer;
    justify-content: start;
    font-size: 12px;
    position: relative;
    white-space: nowrap;
    gap: 2px;
    color: ${props => (props.selectd ? '#ffce3e' : '#ffffff')};
  `,
};
