import { memo, useEffect, useState } from 'react';
import { fill_horizontal_all_center, fill_horizontal_cross_center, fill_vertical_cross_center, fill_vertical_main_center, } from '../../AppLayout/FlexGridCss';
import { css } from '@emotion/react';
import { signalrStore2 } from '../../SDK/Signalr/signalrStore2';
import { signalrHooks2 } from '../../SDK/Signalr/signalrHooks2';
import { useSymbolWatchList } from '../../WatchList/useSymbolWatchList';
import { QuoteBodyChangeFill, QuoteBodyChangeFillHeader } from './QuoteBodyChangeFill';
import { useSnapshot } from 'valtio';
import { scrollbar2Css } from '~/css/scrollbarCss';
import WatchListAddButton from '../WatchListAddButton';
import WatchListAddSmallButton from '../WatchListAddSmallButton';
import { useSignalr2QuoteSort } from '../useSignalr2QuoteSort';
import { Autocomplete, SegmentedControl } from '@mantine/core';
import { AiOutlineSearch } from 'react-icons/ai';
import { store } from '~/pages/heineken_template/_private/store';
import { useSymbolDictionaryStore } from '../../SDK/Symbol/useSymbolDictionaryStore';
import IntradayTrendChart from '../../trendChart';
import styled from '@emotion/styled';
import { removeAfterWhitespace } from '../removeAfterWhitespace';
import QuoteValueItem from '~/modules/investment-consultant/quoteValueBody/QuoteValueBody';
import { useThemeStore } from '~/components/theme/useThemeStore';
import LoadCard from '../LoadCard';
import { useTimeout, useUpdateEffect } from 'react-use';
/** 單純商品列表元件 */
export const SymbolListChangeFill = memo(function SymbolListChangeFill(props) {
    //自選股state
    const { state: watchListState1, acts: watchListActs1 } = useSymbolWatchList(props.watchListGroup1);
    const { state: watchListState2, acts: watchListActs2 } = useSymbolWatchList(props.watchListGroup2);
    const { state: watchListState3, acts: watchListActs3 } = useSymbolWatchList(props.watchListGroup3);
    const symbol = props.symbol ?? '';
    const watchlistMode = props.watchlistMode ?? false;
    const sort = props.sort ?? true;
    //
    useEffect(() => {
        signalrStore2.addQuote(symbol);
        return () => {
            signalrStore2.removeQuote(symbol);
        };
    }, [JSON.stringify(symbol)]);
    const sortSymbolData = useSignalr2QuoteSort(symbol) ?? [];
    //沒有報價的商品
    const allSymbolData = [...new Set([...sortSymbolData, ...symbol])];
    const value = sort === true ? signalrHooks2.useQuotes(sortSymbolData) : signalrHooks2.useQuotes(symbol);
    /** 載入緩衝時間 */
    const [initializeReady, reset] = useTimeout(1250);
    useUpdateEffect(() => {
        reset();
    }, [initializeReady]);
    return (<div css={css `
        ${fill_vertical_cross_center};
      `}>
      {sort === true && (<div css={css `
            width: 100%;
            height: 36px;
          `}>
          <QuoteBodyChangeFillHeader />
        </div>)}
      <div css={css `
          width: 100%;
          ${scrollbar2Css};
          height: calc(100% - 36px);
        `}>
        {symbol.length === 0 && initializeReady() ? (<LoadCard.emtpty />) : (value.length === 0 && <LoadCard.Loading />)}
        {allSymbolData.map((symbol_, index) => {
            return (<QuoteBodyChangeFill symbolNumber={symbol_} quote={value?.filter(s => s.symbol === symbol_)[0]} key={index} listState={'default'} watchlistActs1={watchListActs1} watchListSymbol1={watchListState1.symbolListArray} watchlistActs2={watchListActs2} watchListSymbol2={watchListState2.symbolListArray} watchlistActs3={watchListActs3} watchListSymbol3={watchListState3.symbolListArray} watchlistMode={watchlistMode}/>);
        })}
      </div>
    </div>);
});
/** 只需帶入`商品陣列` `自選股群組1` `自選股群組2` */
//** 等待重構 因為需要多自選股群組功能 原本單一自選股情境已經無法滿足投顧需求 */
export const SymbolPlatformChangeFill = memo(function SymbolPlatformChangeFill(props) {
    //theme
    const theme = useThemeStore(s => s.theme);
    //hook
    const [listState, setListState] = useState('default');
    //自選股state
    const { state: watchListState1, acts: watchListActs1 } = useSymbolWatchList(props.watchListGroup1);
    const { state: watchListState2, acts: watchListActs2 } = useSymbolWatchList(props.watchListGroup2);
    const { state: watchListState3, acts: watchListActs3 } = useSymbolWatchList(props.watchListGroup3);
    const watchlistSymbol1 = watchListState1.symbolListArray;
    const watchlistSymbol2 = watchListState2.symbolListArray;
    const watchlistSymbol3 = watchListState3.symbolListArray;
    const defaultSymbol = props.symbol;
    //當前商品
    const symbol = useSnapshot(store.charting).symbol;
    const symbolData = listState === 'default'
        ? props.symbol
        : listState === 'watchlist1'
            ? watchListState1.symbolListArray
            : listState === 'watchlist2'
                ? watchListState2.symbolListArray
                : watchListState3.symbolListArray;
    const watchlistMode = listState === 'default' ? false : true;
    //商品列有變動重新訂閱
    useEffect(() => {
        signalrStore2.addQuote(symbolData);
        return () => {
            signalrStore2.removeQuote(symbolData);
        };
    }, [JSON.stringify(defaultSymbol), JSON.stringify(watchlistSymbol1), symbolData]);
    //圖表商品有變動重新訂閱
    useEffect(() => {
        signalrStore2.addQuote(symbol);
        return () => {
            signalrStore2.removeQuote(symbol);
        };
    }, [symbol]);
    //排序
    const sortSymbolData = useSignalr2QuoteSort(symbolData) ?? [];
    //沒有報價的商品
    const allSymbolData = [...new Set([...sortSymbolData, ...symbolData])];
    const value = signalrHooks2.useQuotes(sortSymbolData);
    //單一商品報價
    const symbolValue = useSnapshot(signalrStore2.values.quote)[symbol];
    //商品名稱
    const dictionary = useSymbolDictionaryStore(s => s.dictionary);
    const stockSymbols = Object.entries(sortSymbolData).map(s => ({
        symbol: s[1],
        name: dictionary[s[1] ?? ''],
    }));
    /** 載入緩衝時間 */
    const [initializeReady, reset] = useTimeout(1250);
    useUpdateEffect(() => {
        reset();
    }, [initializeReady]);
    return (<classes.Root css={baseStyled}>
      <classes.container className={theme}>
        <classes.header className={theme}>
          <classes.headerSymbol>
            <span>{symbol}</span>
            <span>{dictionary[symbol] ?? ''}</span>
          </classes.headerSymbol>
          <div css={fill_horizontal_cross_center}>
            <QuoteValueItem.arrow.Display quote={symbolValue}/>
            <QuoteValueItem.ohlc.Display quote={symbolValue} ohlcType={'close'}/>
          </div>
          {props.watchlistAddType === 'single' && (<classes.headerButton>
              <WatchListAddButton.Display watchListSymbol={watchListState1.symbolListArray} watchlistActs={watchListActs1}/>
            </classes.headerButton>)}
          {props.watchlistAddType === 'multiple' && (<classes.headerButton>
              <WatchListAddSmallButton.Display watchListSymbol={watchListState1.symbolListArray} watchlistActs={watchListActs1} groupName='自選1'/>
              <WatchListAddSmallButton.Display watchListSymbol={watchListState2.symbolListArray} watchlistActs={watchListActs2} groupName='自選2'/>
              <WatchListAddSmallButton.Display watchListSymbol={watchListState3.symbolListArray} watchlistActs={watchListActs3} groupName='自選3'/>
            </classes.headerButton>)}
        </classes.header>
        <classes.trendChartContent>
          <IntradayTrendChart symbol={symbol} ticksSize={12} priceTicksSize={12} priceTicksMargin={0}/>
        </classes.trendChartContent>
        <classes.defaultContent>
          <Autocomplete icon={<AiOutlineSearch size={22}/>} placeholder='搜尋商品代號' data={stockSymbols.map(s => s.symbol + ' ' + s.name)} variant='unstyled' limit={100} maxDropdownHeight={200} onItemSubmit={item => {
            const symbolNumber = removeAfterWhitespace(item.value);
            store.charting.changeSymbol(symbolNumber);
        }} css={css `
              width: 100%;
              height: 30px;
              border-bottom: 1px solid #565656;
            `}/>
        </classes.defaultContent>
        <classes.SegmentedControlContent>
          <SegmentedControl size='xs' fullWidth={true} data={[
            { label: '熱門商品' + '(' + defaultSymbol?.length + ')', value: 'default' },
            { label: '自選股1' + '(' + watchlistSymbol1?.length + ')', value: 'watchlist1' },
            { label: '自選股2' + '(' + watchlistSymbol2?.length + ')', value: 'watchlist2' },
            { label: '自選股3' + '(' + watchlistSymbol3?.length + ')', value: 'watchlist3' },
        ]} onChange={val => {
            setListState(val);
        }}/>
        </classes.SegmentedControlContent>
        <classes.defaultContent>
          <QuoteBodyChangeFillHeader />
        </classes.defaultContent>

        <classes.symnbolListConstent>
          {value.length === 0 && initializeReady() ? (<LoadCard.emtpty />) : (value.length === 0 && <LoadCard.Loading />)}

          {allSymbolData.map((symbol_, index) => {
            return (<QuoteBodyChangeFill symbolNumber={symbol_} quote={value?.filter(s => s.symbol === symbol_)[0]} key={index} listState={listState} watchlistActs1={watchListActs1} watchListSymbol1={watchListState1.symbolListArray} watchlistActs2={watchListActs2} watchListSymbol2={watchListState2.symbolListArray} watchlistActs3={watchListActs2} watchListSymbol3={watchListState2.symbolListArray} watchlistMode={watchlistMode}/>);
        })}
        </classes.symnbolListConstent>
      </classes.container>
    </classes.Root>);
});
const classes = {
    Root: styled.div `
    width: 100%;
    height: 100%;
  `,
    container: styled.div `
    ${fill_vertical_cross_center};
  `,
    header: styled.div `
    ${fill_horizontal_all_center};
    display: grid;
    grid-template-columns: 20% 20% 60%;
    width: 100%;
    height: 40px;
    font-size: 13px;
    padding: 0px 4px;
    white-space: nowrap;
  `,
    headerSymbol: styled.div `
    ${fill_vertical_main_center};
    & > * {
      line-height: 14px;
    }
  `,
    headerButton: styled.div `
    ${fill_horizontal_all_center};
    height: 100%;
    padding: 4px;
    gap: 4px;
  `,
    trendChartContent: styled.div `
    height: 140px;
    width: 100%;
  `,
    SegmentedControlContent: styled.div `
    width: 100%;
    height: 36px;
    padding: 0px 4px;
  `,
    defaultContent: styled.div `
    width: 100%;
    height: 36px;
  `,
    symnbolListConstent: styled.div `
    ${scrollbar2Css};
    width: 100%;
    height: calc(100% - 280px);
  `,
};
const baseStyled = css `
  ${classes.Root} {
  }
  ${classes.container} {
  }
  ${classes.header} {
    border-bottom: 1px solid #45454545;
  }
  ${classes.SegmentedControlContent} {
    .mantine-SegmentedControl-root {
      background-color: #1d1e20;
    }
  }
`;
